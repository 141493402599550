import {makeSearchParams} from './query';

const noop = () => {};

type RawQuery = {
  [key: string]: unknown;
};

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

type BaseOptions = {
  query?: RawQuery;
  data?: unknown;
  multipart?: boolean;
  headers?: {[key: string]: string};
  onError?: (info: {
    meta: {url: URL; response?: Response};
    error: Error;
  }) => unknown;
  onRequest?: (info: {meta: {url: URL}; data?: unknown}) => unknown;
  redirect?: 'follow' | 'manual' | 'error';
};

export type RequestOptions = BaseOptions & {
  url: string | URL;
  method?: Method;
};

export async function makeRequest(options: RequestOptions): Promise<Response> {
  const {
    method = 'GET',
    data,
    headers,
    multipart = false,
    redirect = 'follow',
    onError = noop,
    onRequest = noop,
  } = options;
  let {query} = options;

  const url = new URL(options.url.toString());

  if (query) {
    url.search = makeSearchParams(query).toString();
  }

  // TODO (kyle): determine whether or not this is necessary.
  const init: RequestInit = {
    method,
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect,
  };

  let headersInit: HeadersInit = {
    Accept: 'application/json',
    ...headers,
  };

  if (['POST', 'PUT', 'DELETE'].includes(method)) {
    if (data && data instanceof FormData) {
      init.body = data;
    } else if (typeof data === 'object' && data && multipart) {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        let val = Reflect.get(data, key);
        if (!(val instanceof File)) {
          val = JSON.stringify(val);
        }
        if (val !== undefined) {
          formData.set(key, val);
        }
      });
      init.body = formData;
    } else {
      init.body = JSON.stringify(data);
      Reflect.set(headersInit, 'Content-Type', 'application/json');
    }

    onRequest({
      data,
      meta: {url},
    });
  }

  if (method === 'DELETE') {
    onRequest({
      meta: {url},
    });
  } else if (method === 'GET') {
    headersInit = noCacheHeaders(headersInit);
  }

  init.headers = headersInit;

  try {
    const response = await fetch(url.toString(), init);

    if (!response.ok) {
      onError({
        error: new Error(),
        meta: {response, url},
      });
    }

    return response;
  } catch (error) {
    onError({
      error,
      meta: {url},
    });
    throw error;
  }
}

export class Api {
  _baseUrl: URL;
  _baseOptions: BaseOptions;

  constructor(baseUrl: URL | string = '', baseOptions?: BaseOptions) {
    this._baseUrl = new URL(baseUrl.toString());
    this._baseOptions = baseOptions ?? {};
  }

  async _makeRequest(path: string, method: Method, options?: BaseOptions) {
    const url = new URL(this._baseUrl.toString());
    url.pathname += path;

    const requestOptions = {
      ...this._baseOptions,
      ...options,
      headers: {
        ...this._baseOptions.headers,
        ...options?.headers,
      },
      url,
      method,
    };

    const response = await makeRequest(requestOptions);

    const text = await response.text();

    let json;
    if (response.headers.get('Content-Type')?.includes('application/json')) {
      try {
        json = JSON.parse(text);
      } catch (error) {
        // NOTE (kyle): this should not happen.
        throw new ApiError(requestOptions, response, text, error);
      }
    } else {
      json = text;
    }

    if (!response.ok) {
      throw new ApiError(requestOptions, response, json);
    }

    return json;
  }

  setBasePath(basePath: string) {
    this._baseUrl.pathname = basePath;
  }

  setBaseOptions(baseOptions: BaseOptions) {
    this._baseOptions = {
      ...this._baseOptions,
      ...baseOptions,
      headers: defined({
        ...this._baseOptions.headers,
        ...baseOptions.headers,
      }),
    };
  }

  setHeaders(headers: {[key: string]: string | null | void}) {
    this._baseOptions = {
      ...this._baseOptions,
      headers: defined({
        ...this._baseOptions.headers,
        ...headers,
      }),
    };
  }

  get(path: string, query?: RawQuery, options?: BaseOptions) {
    return this._makeRequest(path, 'GET', {
      ...options,
      query,
    });
  }

  post(path: string, data?: unknown, options?: BaseOptions) {
    return this._makeRequest(path, 'POST', {
      ...options,
      data,
    });
  }

  put(path: string, data?: unknown, options?: BaseOptions) {
    return this._makeRequest(path, 'PUT', {
      ...options,
      data,
    });
  }

  delete(path: string, options?: BaseOptions) {
    return this._makeRequest(path, 'DELETE', options);
  }
}

export class ApiError extends Error {
  options: RequestOptions;
  response: Response;
  responseBody: unknown;
  error: Error;
  fetchError: Error;

  constructor(
    options: RequestOptions,
    response: Response,
    responseBody?: unknown,
    error?: Error,
  ) {
    super('ApiError');

    this.options = options;
    this.response = response;
    this.responseBody = responseBody;
    // TODO (kyle): change this to `fetchError`
    this.error = this.fetchError = error || new Error();
  }
}

// TODO (kyle): reexamine whether this is necessary or desired when using the fetch api
function noCacheHeaders(headers: HeadersInit): HeadersInit {
  return {
    ...headers,
    'X-Requested-With': 'XMLHttpRequest',
    Expires: '-1',
    'Cache-Control': 'no-cache,no-store,must-revalidate,max-age-1,private',
  };
}

function defined<T extends Object>(object: T) {
  return Object.fromEntries(
    Object.entries(object).filter(([key, value]) => value != null),
  );
}
