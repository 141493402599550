import * as React from 'react';
import styled from 'styled-components';

export const FlexRoot = styled.div`
  display: flex;
  flex-flow: column;

  & div, & form {
    display: flex;
  }
`;

export const Root = styled.div`
  margin: auto;
  flex-flow: column;
`;

function _TextInput(props: JSX.IntrinsicElements['input']) {
  return <input type="text" {...props} />;
}

export const TextInput = styled(_TextInput)`
  border: 1px solid black;
  padding: 4px 10px;
`;

export const Button = styled.button`
  border: 1px solid black;
  height: 30px;
  border-radius: 15px;
`;
