import * as React from 'react';
import styled from 'styled-components';
import {Link} from '@malectro/rerouter';
import {getAuth, signOut, User} from 'firebase/auth';
import {useUser} from './user';
import {FlexRoot, Root, Button} from './ui';
import {useResource} from './utils/useResource';
import {useNetWorth} from './useRealtimeData';

export function Home(): JSX.Element {
  const user = useUser();
  return (
    <FlexRoot>
      <Root>
        {user ? (
          <UserHome user={user} />
        ) : (
          <>
            <span>You are logged out</span>
            <Link to="/signin">Sign in</Link> or{' '}
            <Link to="/signup">Sign up</Link>
          </>
        )}
      </Root>
    </FlexRoot>
  );
}

function UserHome({user}: {user: User}): JSX.Element {
  const {netWorth, isLoading} = useNetWorth(user.uid);
  return (
    <>
      <span>Welcome {user.displayName || user.uid}!</span>
      <span>You are logged in.</span>
      <span>
        Your net worth is{' '}
        {(netWorth || 0).toLocaleString(undefined, {
          style: 'currency',
          currency: 'USD',
        })}
        .
      </span>
      {netWorth != null && netWorth > 1_000 ? (
        <Link to="/room">Go to the room</Link>
      ) : (
        <span>You are too poor to enter the room.</span>
      )}
      <Link to="/link">Link account</Link>
      <Button onClick={() => signOut(getAuth())}>Sign out</Button>
    </>
  );
}
