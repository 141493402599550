import { initializeApp } from "firebase/app"

const firebaseApp = initializeApp({
    apiKey: "AIzaSyD-nzOwNZ2eUKyQdFyFx4A5e7qVp_GdFII",
    authDomain: "party-line-c92c2.firebaseapp.com",
    projectId: "party-line-c92c2",
    storageBucket: "party-line-c92c2.appspot.com",
    messagingSenderId: "522458243251",
    appId: "1:522458243251:web:5da6af113da8eecd1f90c6"
});
