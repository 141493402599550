import * as React from 'react';
import {useRoutes} from '@malectro/rerouter';
import styled from 'styled-components';
import {useUser} from './user';
import {useNetWorth} from './useRealtimeData';

import './App.css';

import {NotFound} from './NotFound';
import {SignIn, SignUp} from './SignIn';
import {Home} from './Home';
import {LinkPlaid} from './LinkPlaid';
import {PrivateRoom} from './PrivateRoom';

function App() {
  const user = useUser();

  console.log('user', user);
  // @ts-ignore
  window.user = user;

  const [content] = useRoutes([
    {path: '', exact: true, element: <Home />},
    {path: 'signin', element: <SignIn />},
    {path: 'signup', element: <SignUp />},
    {path: 'link', element: <LinkPlaid />},
    {path: 'room', element: <PrivateRoom />},
    {path: '*', element: <NotFound />},
  ]);

  return (
    <>{user !== undefined ? content : null}</>
  );
}

export default App;
