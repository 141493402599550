import * as React from 'react';
import styled from 'styled-components';
import {useHistory, Link} from '@malectro/rerouter';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import {FlexRoot, TextInput, Button} from './ui';
import {useAsync} from './utils/useResource';

import {api} from './api';

type SignInState = {
  email: string;
  password: string;
};

export function SignIn(): JSX.Element {
  const history = useHistory();

  const [state, setState] = React.useState<SignInState>({
    email: '',
    password: '',
  });

  const updateState = (update: Partial<SignInState>) =>
    setState((state) => ({...state, ...update}));

  const [signIn, {isLoading, error}] = useAsync(() => {
    const {email, password} = state;
    return signInWithEmailAndPassword(getAuth(), email, password);
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    await signIn();

    history.replace('/');
  };

  return (
    <FlexRoot>
      <AuthRoot onSubmit={handleSubmit}>
        <h2>Sign in</h2>
        <TextInput
          type="email"
          value={state.email}
          onChange={(event) => updateState({email: event.currentTarget.value})}
          placeholder="email"
        />
        <TextInput
          type="password"
          value={state.password}
          onChange={(event) =>
            updateState({password: event.currentTarget.value})
          }
          placeholder="password"
        />
        <Button>{isLoading ? '...' : 'Submit'}</Button>
        <span>
          Or <Link to="/signup">create an account</Link>
        </span>
      </AuthRoot>
    </FlexRoot>
  );
}

type SignUpState = {
  displayName: string;
  email: string;
  password: string;
};

export function SignUp(): JSX.Element {
  const history = useHistory();

  const [state, setState] = React.useState<SignUpState>({
    email: '',
    password: '',
    displayName: '',
  });

  const updateState = (update: Partial<SignUpState>) =>
    setState((state) => ({...state, ...update}));

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    //api.post('auth', state);
    const {email, password, displayName} = state;
    const {user} = await createUserWithEmailAndPassword(
      getAuth(),
      email,
      password,
    );
    await updateProfile(user, {displayName});

    history.replace('/');
  };

  return (
    <AuthRoot onSubmit={handleSubmit}>
      <h2>Sign up</h2>
      <TextInput
        name="displayName"
        value={state.displayName}
        onChange={(event) =>
          updateState({displayName: event.currentTarget.value})
        }
        placeholder="username"
      />
      <TextInput
        type="email"
        value={state.email}
        onChange={(event) => updateState({email: event.currentTarget.value})}
        placeholder="email"
      />
      <TextInput
        type="password"
        value={state.password}
        onChange={(event) => updateState({password: event.currentTarget.value})}
        placeholder="password"
      />
      <Button>Submit</Button>
      <span>
        Or <Link to="/signin">sign in</Link>
      </span>
    </AuthRoot>
  );
}

const AuthRoot = styled.form`
  margin: auto;
  max-width: 400px;
  flex-flow: column;

  & > * + * {
    margin: 10px 0 0;
  }
`;
