import * as React from 'react';
import styled from 'styled-components';
import {usePlaidLink} from 'react-plaid-link';
import {useHistory} from '@malectro/rerouter';
import {FlexRoot, Root, Button} from './ui';
import {useResource, useAsync} from './utils/useResource';
import {api} from './api';

export function LinkPlaid(): JSX.Element {
  const [{result, isLoading, error}] = useResource(
    () => api.post('create_link_token'),
    [],
  );

  return (
    <FlexRoot>
      {result ? (
        <Step2 token={result.link_token} />
      ) : error ? (
        <>Failed to generate token</>
      ) : (
        <>Loading</>
      )}
    </FlexRoot>
  );
}

function Step2({token}: {token: string}): JSX.Element {
  const [inProgress, setInProgress] = React.useState(true);

  const history = useHistory();
  const [getBalance, balanceState] = useAsync(async (body) => {
    await api.post('get_access_token', body);
    history.replace('/');
  });

  const {open, ready} = usePlaidLink({
    token,
    onSuccess: async (
      public_token: string,
      metadata: {institution: {institution_id: string; name: string}},
    ) => {
      console.log('success', public_token, metadata);
      await getBalance({public_token, institution: metadata.institution});
      setInProgress(false);
    },
  });

  React.useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready]);

  return inProgress ? (
    <>Loading</>
  ) : (
    <div>
      got accounts
      <pre>{JSON.stringify(balanceState.result, null, ' ')}</pre>
    </div>
  );
}
