import {useState, useEffect} from 'react';
import {getDatabase, ref, onValue} from 'firebase/database';
import {useUser} from './user';

export function useRealtimeData<T>(
  refString: string,
): {
  data: T | null;
  isLoading: boolean;
} {
  const [state, setState] = useState({data: null, isLoading: true});

  useEffect(() => {
    return onValue(ref(getDatabase(), refString), (snapshot) => {
      setState({
        data: snapshot.val(),
        isLoading: false,
      });
    });
  }, [ref]);

  return state;
}

export function useNetWorth(
  uid: string,
): {isLoading: boolean; netWorth: number | null} {
  const {data, isLoading} = useRealtimeData<{
    [institution_id: string]: {
      [index: string]: {
        balance: number;
        // TODO (kyle): other stuff
      },
    },
  }>(`accounts/${uid}`);

  let netWorth = null;
  if (data) {
    netWorth = 0;
    for (const accounts of Object.values(data)) {
      for (const account of Object.values(accounts)) {
        netWorth += account.balance;
      }
    }
  }

  return {netWorth, isLoading};
}
