import * as React from 'react';
import styled from 'styled-components';
import Daily, {
  DailyCall,
  DailyEventObject,
  DailyEventObjectFatalError,
} from '@daily-co/daily-js';
import {User} from 'firebase/auth';
import {Link, useHistory} from '@malectro/rerouter';
import {useUser} from './user';
import {useResource} from './utils/useResource';
import {api} from './api';
import {useNetWorth} from './useRealtimeData';

import theme from './theme';
import logo from './icons/logo.svg';
import {SmallText} from './daily-components/shared/SmallText';
import CopyLinkBox from './daily-components/CopyLinkBox';
import Participant from './daily-components/Participant';
import MicIcon from './daily-components/MicIcon';
import MutedIcon from './daily-components/MutedIcon';

export function PrivateRoom(): JSX.Element {
  const user = useUser();
  if (!user) {
    throw new Error('Unauthorized');
  }

  const {netWorth, isLoading} = useNetWorth(user.uid);

  return isLoading || !user ? (
    <></>
  ) : (
    <Inside user={user} netWorth={netWorth || 0} />
  );
}

function Inside({user, netWorth}: {user: User; netWorth: number}): JSX.Element {
  const [{result: call, error, isLoading}] = useResource(async () => {
    if (!user) {
      throw new Error('no user');
    }

    const call = Daily.createCallObject({
      // @ts-ignore
      audioSource: true, // start with audio on to get mic permission from user at start
      // @ts-ignore
      videoSource: false,
      dailyConfig: {
        experimentalChromeVideoMuteLightOff: true,
      },
    });

    const token = await api.post('room-token');

    await call.join({
      url: `https://${process.env.REACT_APP_DAILY_DOMAIN}.daily.co/kylesroom`,
      // @ts-ignore
      userName: netWorth,
      token,
    });

    return call;
  }, [user]);

  React.useEffect(() => {
    if (call) {
      // TODO (kyle): handle call events
      return () => {
        call.leave();
        call.destroy();
      };
    }
  }, [call]);

  return (
    <>
      {isLoading ? 'connecting...' : call ? <DailyRoom call={call} /> : 'error'}
    </>
  );
}

function DailyRoom({call}: {call: DailyCall}) {
  const history = useHistory();
  const {participants, activeSpeakerId} = useCall(call);

  const participantsArray = [...participants.values()];
  const local = participantsArray.find((participant) => participant.local);

  const handleLeave = () => {
    history.push('/');
  };

  const handleAudioChange = () => {
    call.setLocalAudio(!local.audio);
  };

  return (
    <AppContainer>
      <Wrapper>
        <Header>
          <HeaderTop>
            <Title>Country Club House</Title>
          </HeaderTop>
          <SmallText>A drastic exercise in nihilism</SmallText>
        </Header>
        <Container hidden={false}>
          <CallHeader>
            <RoomHeader>Speakers</RoomHeader>
          </CallHeader>
          <CanSpeakContainer>
            {participantsArray.map((p, i) => (
              <Participant
                participant={p}
                key={i}
                local={local}
                modCount={participantsArray.length}
                onMute={() => call.setLocalAudio(false)}
                onUnmute={() => call.setLocalAudio(true)}
                onLeave={handleLeave}
                activeSpeakerId={activeSpeakerId}
              />
            ))}
          </CanSpeakContainer>
          {/*<CopyLinkBox room={{name: 'Country Club House'}} />*/}
          <Tray>
            <TrayContent>
              <AudioButton onClick={handleAudioChange}>
                {local?.audio ? (
                  <MicIcon type="simple" />
                ) : (
                  <MutedIcon type="simple" />
                )}
                <ButtonText>{local?.audio ? 'Mute' : 'Unmute'}</ButtonText>
              </AudioButton>
              <LeaveButton onClick={handleLeave}>Leave call</LeaveButton>
            </TrayContent>
          </Tray>
        </Container>
      </Wrapper>
    </AppContainer>
  );
}

function useCall(call: DailyCall) {
  const [state, setState] = React.useState<{
    participants: Map<string, {}>;
    activeSpeakerId: string | null;
  }>({
    participants: new Map(),
    activeSpeakerId: null,
  });

  React.useEffect(() => {
    if (!call) {
      return;
    }

    const handleError = (
      error: DailyEventObjectFatalError | null | undefined,
    ) => {
      console.warn('Call Error', error);
    };

    const handleParticipantsUpdate = () => {
      setState((state) => ({
        ...state,
        participants: new Map(Object.entries(call.participants())),
      }));
    };

    const handleActiveSpeakerChange = (
      event?: DailyEventObject<'active-speaker-change'>,
    ) => {
      setState((state) => ({
        ...state,
        activeSpeakerId: event?.activeSpeaker?.peerId || null,
      }));
    };

    call.on('error', handleError);
    //callFrame.on("joined-meeting", handleJoinedMeeting);
    call.on('participant-joined', handleParticipantsUpdate);
    call.on('participant-updated', handleParticipantsUpdate);
    call.on('participant-left', handleParticipantsUpdate);
    call.on('track-started', handleParticipantsUpdate);
    call.on('track-stopped', handleParticipantsUpdate);
    //callFrame.on("app-message", handleAppMessage);
    call.on('active-speaker-change', handleActiveSpeakerChange);

    handleParticipantsUpdate();

    return () => {
      call.off('error', handleError);
      //callFrame.on("joined-meeting", handleJoinedMeeting);
      call.off('participant-joined', handleParticipantsUpdate);
      call.off('participant-updated', handleParticipantsUpdate);
      call.off('participant-left', handleParticipantsUpdate);
      call.off('track-started', handleParticipantsUpdate);
      call.off('track-stopped', handleParticipantsUpdate);
      //callFrame.on("app-message", handleAppMessage);
      call.off('active-speaker-change', handleActiveSpeakerChange);
    };
  }, [call]);

  return state;
}

const AppContainer = styled.div`
  display: block;
  background-color: ${theme.colors.greyLightest};
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const Wrapper = styled.div`
  max-width: 700px;
  padding: 32px 24px 0;
  min-height: 100%;
  margin: 0 auto;
`;
const Logo = styled.img`
  height: 24px;
`;
const Header = styled.header`
  display: flex;
  flex-direction: column;
`;
const HeaderTop = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.h1`
  font-size: ${theme.fontSize.xxlarge};
  color: ${theme.colors.blueDark};
  margin: 4px 0;
  font-weight: 600;
`;

const Container = styled.div`
  margin: 48px 0 0;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
  height: ${(props) => (props.hidden ? '0' : '100%')};
`;
const CanSpeakContainer = styled.div`
  border-bottom: ${theme.colors.grey} 1px solid;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
`;
const ListeningContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
`;
const RoomHeader = styled.h2`
  font-size: ${theme.fontSize.large};
  color: ${theme.colors.greyDark};
`;
const CallHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;
const Tray = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 52px;
  width: 100vw;
  box-sizing: border-box;
  background-color: ${theme.colors.greyLight};
  padding: 12px;
`;
const TrayContent = styled.div`
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Button = styled.button`
  font-size: ${theme.fontSize.large};
  font-weight: 600;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${theme.colors.greyLightest};
  }
`;
const LeaveButton = styled(Button)`
  margin-left: auto;
`;
const HandButton = styled(Button)`
  margin-right: auto;
`;
const AudioButton = styled(Button)`
  margin-right: auto;
  display: flex;
  align-items: center;
`;
const ButtonText = styled.span`
  margin-left: 4px;
`;
