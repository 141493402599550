import {useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';
import useClickAway from '../useClickAway';
import MicIcon from './MicIcon';
import MutedIcon from './MutedIcon';
import MoreIcon from './MoreIcon';
import Menu from './Menu';
import theme from '../theme';

const AVATAR_DIMENSION = 80;
const ADMIN_BADGE = '⭐ ';

const initials = (name) =>
  name
    ? name
        .split(' ')
        .map((n) => n.charAt(0))
        .join('')
    : '';

const Participant = ({
  participant,
  local,
  modCount,
  onMute,
  onUnmute,
  activeSpeakerId,
  onLeave,
}) => {
  const audioRef = useRef(null);
  const {ref, isVisible, setIsVisible} = useClickAway(false);

  const name = participant?.user_name;

  const mutedText = participant?.audio ? 'Mute' : 'Unmute';

  const audioAction = participant?.audio
    ? (id) => onMute()
    : (id) => onUnmute();

  /**
   * Determine what the menu options are based on the account type.
   * Listeners can't unmute but can raise their hand to speaker.
   * Moderators can change the status of others but can't have their
   * own status change to speaker or listener.
   * Moderators cannot unmute but can mute.
   */
  let menuOptions = [];

  /**
   * If it's the local particpant's menu:
   *  - Mods can unmute themselves and speakers.
   *  - Speakers can unmute themselves.
   *  - Listeners listen. :)
   */
  if (participant?.local) {
    menuOptions.push({
      text: mutedText,
      action: () => audioAction(participant),
    });
  }

  /**
   * Let the local participant leave. (There's also
   * a button in the tray.) "Leave" or "Remove" should
   * be the last items
   */
  if (participant?.local) {
    const lastMod = false;
    menuOptions.push({
      text: lastMod ? 'End call' : 'Leave call',
      action: onLeave,
      warning: true,
    });
  }

  useEffect(() => {
    if (!participant?.audioTrack || !audioRef.current) return;
    // sanity check to make sure this is an audio track
    if (
      participant?.audioTrack?.track &&
      !participant?.audioTrack?.track?.kind === 'audio'
    )
      return;
    // don't play the local audio track (echo!)
    if (participant?.local) return;
    // set the audio source for everyone else
    audioRef.current.srcObject = new MediaStream([participant?.audioTrack]);
  }, [participant?.audioTrack]);

  const showMoreMenu = participant?.local;

  return (
    <Container>
      <Avatar
        muted={!participant?.audio}
        isActive={activeSpeakerId === participant?.user_id}
      >
        <AvatarText>
          {participant?.owner ? ADMIN_BADGE : ''}
          {getNetWorthEmoji(name)}
        </AvatarText>
      </Avatar>
      <Name>{parseInt(name).toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        notation: 'compact',
      })}</Name>
      <AudioIcon>{participant?.audio ? <MicIcon /> : <MutedIcon />}</AudioIcon>
      {showMoreMenu && menuOptions.length > 0 && (
        <MenuButton onClick={() => setIsVisible(!isVisible)}>
          <MoreIcon />
        </MenuButton>
      )}
      {isVisible && (
        <MenuContainer ref={ref}>
          <Menu options={menuOptions} setIsVisible={setIsVisible} />
        </MenuContainer>
      )}
      {participant?.audioTrack && (
        <audio
          autoPlay
          id={`audio-${participant.user_id}`}
          playsInline
          ref={audioRef}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  align-items: flex-start;
  position: relative;
  max-width: 104px;
`;
const Avatar = styled.div`
  width: ${AVATAR_DIMENSION}px;
  height: ${AVATAR_DIMENSION}px;
  border-radius: 24px;
  background-color: ${(props) =>
    props.muted ? theme.colors.grey : theme.colors.turquoise};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid
    ${(props) => (props.isActive ? theme.colors.teal : 'transparent')};
`;
const AvatarText = styled.p`
  font-size: ${theme.fontSize.large};
  color: ${theme.colors.blueLight};
  font-weight: 600;
  line-height: 32px;
`;
const Name = styled.p`
  color: ${theme.colors.blueDark};
  margin: 8px 0;
  font-weight: 400;
  font-size: ${theme.fontSize.base};
  padding-left: 4px;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
`;
const AudioIcon = styled.div`
  position: absolute;
  top: ${AVATAR_DIMENSION - 28}px;
  left: -4px;
`;
const MenuButton = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  top: ${AVATAR_DIMENSION - 28}px;
  right: -4px;
  padding: 0;
  cursor: pointer;
`;
const MenuContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
`;

export default Participant;

function getNetWorthEmoji(netWorth: number): string {
  const emojis = ['💵', '💰', '🤑', '💸', '🏦', '💎'];
  const minimum = 1_000;

  if (netWorth < minimum) {
    return '💩';
  }

  let position =
    netWorth >= minimum ? Math.floor(Math.log2(netWorth / minimum)) : 0;
  if (position > emojis.length - 1) {
    position = emojis.length - 1;
  }
  return emojis[position];
}
