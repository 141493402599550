import * as React from 'react';
import {getAuth, onAuthStateChanged, User} from 'firebase/auth';
import {api} from './api';

type MaybeUser = User | null | undefined;

export const UserContext = React.createContext<MaybeUser>(null);

export function useUserListener() {}

export function UserProvider({children}: {children: React.ReactNode}) {
  const [user, setUser] = React.useState<MaybeUser>();

  React.useEffect(() => {
    const handler = async (user: User | null) => {
      const idToken = user
        ? await user.getIdToken().catch((error) => {
            console.error(error);
          })
        : null;
      api.setHeaders({
        'X-Auth-Token': idToken,
      });
      setUser(user);
    };

    return onAuthStateChanged(getAuth(), handler);
  }, []);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useUser(): MaybeUser {
  return React.useContext(UserContext);
}
